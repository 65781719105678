import { graphql, useStaticQuery } from "gatsby"

import Fields from '../components/fields'
import Layout from '../layouts'
import PageHeader from '../components/page/header'
import Photos from '../components/photos'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'

const Training = () => {
  const { pageHeaderImage, fields, photoOne, photoTwo } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "personal-training-hero.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }

        photoOne: imageSharp(resize: {originalName: {eq: "personal-training-kinesio-test.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        photoTwo: imageSharp(resize: {originalName: {eq: "personal-training-augen-test.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        fields: allPersonalTrainingJson {
          edges {
            node {
              name
              copy
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Personal Training'
        currentUrl='/personal-training'
      />
      <PageHeader
        headline='Personal Training'
        copy='Erleben Sie ein ganzheitliches effektives Training für Ihre individuellen Ziele, indem wir einzigartig neurozentriertes Training mit funktionellem Training verbinden.'
        image={pageHeaderImage}
      />
    <Text text='Das Personal Training bietet Ihnen die Möglichkeit Ihre persönlichen Ziele mit wenig Zeitaufwand auf direktem Wege zu erreichen und das Maximum aus jeder Trainingseinheit herauszuholen. Das Training findet in einer angenehmen sportlichen Atmosphäre in meiner Sport- und Therapiepraxis statt und ist speziell auf Sie zugeschnitten.'/>
      <Fields fields={fields?.edges.map((node) => node.node )}/>
      <Text text={`
## So läuft das Personal Training ab
Die 1:1-Betreuung beginnt mit einem Erstgespräch zur Festlegung Ihrer individuellen Trainingsziele. Anschließend legen wir in einer ausführlichen Anamnese fest, wo wir starten und erfassen Ihre körperlichen Stärken und Schwächen. Mit Beweglichkeit-, Neurologischen Tests und Krafttests erkennen wir genau wo wir ansetzen müssen, um Ihre Trainingsziele in die Realität umsetzen zu können. Erst dann beginnen wir mit dem eigentlichen Training.`
      }/>
      <Photos photos={[{ photo: photoOne, alt: "Kinesiologische Testung" }]}/>
      <Text text={`
## Inhalte des Trainings
Das Training setzt sich aus verschiedenen Elementen zusammen, die stark davon abhängen, was Ihr persönliches Ziel ist. Im Zentrum steht aber immer ein funktionelles Training mit vielen Aspekten aus der Neuro-Athletik.
Für alle, die funktionell und smart trainieren wollen.
Erleben Sie eine ganzheitliche Trainingseinheit, die Sie im Alltag agiler macht. Koordination und Reaktion trifft auf Beweglichkeit und Kraft.
      `}/>
      <Photos photos={[{ photo: photoTwo, alt: "Synchronisierung der Augen" }]}/>
      <Text text={`
## Umfassende Betreuung
Das Training wird auf Wunsch durch ein Ernährungscoaching ergänzt, um größtmögliche Erfolge möglich zu machen und Ihre tägliche Leistungsfähigkeit zu steigern. Eine regelmäßige Erfolgsmessung garantiert außerdem, dass wir stets auf dem richtigen Weg sind, um Ihre persönlichen Ziele zu erreichen.

## Bewegung entsteht im Gehirn
Das ist der Kern meiner Philosophie. Körperliches Training, ohne das gesamte athletische Potenzial mit einzubeziehen, ergibt für mich einfach keinen Sinn. Ganzheitlich und funktionell sind elementare Eigenschaften, die ich bei jeder Trainingsplanung beherzige.
Jeder hat athletisches Potenzial, jeder darf frei von Schmerzen sein und seine Stärke entfesselt ausleben - das ist der Grundsatz an den ich glaube, und der mich antreibt. Das Ziel von Unleashed Therapy ist es, Ihres zu entdecken, zu fördern und zum Vorschein zu bringen.
      `}/>
    </Layout>
  )
}

export default Training
